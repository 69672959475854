import { useDates } from 'hooks/dates';

import StrictUnion from 'util/StrictUnion';
export interface ProductiveErrorModel {
    status: number;
    title: string;
    detail: string;
}

export interface ProductiveIncludedModel {
    id: string;
    type: string;
    attributes: ProductiveAttributesModel;
    relationships: ProductiveRelationshipsModel;
}

export interface ProductiveMetaModel {
    current_page?: number;
    total_pages?: number;
}
export interface ProductiveDataModel {
    id: string;
    type: string;
    attributes: ProductiveAttributesModel;
    relationships: ProductiveRelationshipsModel;
}
export interface ProductiveAttributesModel {
    [key: string]: string | ProductiveAttributesObjectModel;
}
export interface ProductiveAttributesObjectModel {
    [keyof: string]: string;
}

export interface ProductiveRelationshipsModel {
    [key: string]: {
        data: {
            id: string;
            name: string;
        }
    }
}

export interface ProductiveRelationshipsArrayModel {
    [key: string]: {
        data: {
            id: string;
            name: string;
        }[]
    }
}
export interface ProductiveResponse<T> extends Response {
    data?: T;
    errors?: ProductiveErrorModel[];
}

export type TaskQueryParams = {
    due_date_on?: Date | string;
}

export type PersonQueryParams = {
    personId?: number;
    status?: 1 | 2; // 1 = active, 2 = inactive
}

export type BookingQueryParams = {
    before?: Date | string;
    after?: Date | string;
}

export type CustomFieldQueryParams = {
    name?: string;
}

export type ServiceQueryParams = {
    deal_id?: string[];
}

export type ProjectQueryParams = {
    custom_fields?: CustomFieldFilterParams;
}

export type CustomFieldComparisonAttributes = 'eq' | 'lt_eq' | 'gt_eq';

export type CustomFieldFilterParams = {
    [keyof: number]: {
        [key in CustomFieldComparisonAttributes]?: number | number[] | string | string[] | Date | Date[]
    }
}

export type FilterQueryParams = StrictUnion<PersonQueryParams
    | BookingQueryParams
    | CustomFieldQueryParams
    | TaskQueryParams
    | ServiceQueryParams
    | ProjectQueryParams>;

export interface ProductivePaginationQueryParams {
    number?: number;
    size?: number;
}
export interface ProductiveQueryParams {
    filters?: FilterQueryParams;
    pagination?: ProductivePaginationQueryParams;
}

export const findInIncludedByTypeAndId = (includedItems: ProductiveIncludedModel[] | undefined, includedType: string, includedId: string) => includedItems?.find(i => i.type === includedType && i.id === includedId);

//type CallProductiveApiFn = <T>(url: string, options?: RequestInit | undefined) => Promise<ProductiveResponse<T>>;

export const useProductiveApi = () => {
    const { format, defaultDateFormat } = useDates();

    const getFullUrl = (url: string, queryParams?: ProductiveQueryParams) => {
        const endpoint = 'https://api.productive.io/api/v2';
        const fullUrl = `${endpoint}${url}`;

        let allQueryParams: string[] = [];

        if (queryParams?.filters) {
            const filterQueryParams = Object.entries(queryParams.filters)
                .map(([k, v]) => {
                    const value = v instanceof Date
                        ? format(v, defaultDateFormat)
                        : v;

                    return [k, value];
                })
                .map(([k, v]) => {
                    const isCustomFieldQueryFilter = k === 'custom_fields';

                    if (isCustomFieldQueryFilter) {
                        const customFieldsFilters = Object.keys(v);
                        return customFieldsFilters.reduce((acc, key) => {
                            const filterValues = Object.entries(v[key])
                                .flatMap(([k, v]) => Array.isArray(v)
                                    ? v.map((vElem: any) => `[${k}][]=${vElem}`)
                                    : [`[${k}]=${v}`]);

                            const filterValuesString = filterValues.reduce((totalFilter, currentFilter) => {
                                const currentFilterValueString = `filter[custom_fields][${key}]${currentFilter}`;
                                return totalFilter
                                    ? `${totalFilter}&${currentFilterValueString}`
                                    : currentFilterValueString;
                            }, '');
                            return acc
                                ? `${acc}&${filterValuesString}`
                                : filterValuesString;
                        }, '');
                    } else {
                        const isFilterOptionArray = Array.isArray(v);

                        return isFilterOptionArray
                            ? v.map((vElem: any) => `filter[${k}][]=${vElem}`).join('&')
                            : `filter[${k}]=${v}`;
                    }
                });


            allQueryParams = [...allQueryParams, ...filterQueryParams];
        }

        if (queryParams?.pagination) {
            const paginationQueryParams = Object.entries(queryParams.pagination)
                .map(([k, v]) => `page[${k}]=${v}`);

            allQueryParams = [...allQueryParams, ...paginationQueryParams];
        }

        const queryString = allQueryParams.reduce((a, b) => {
            if (a) {
                return `${a}&${b}`;
            }

            return b;
        }, '');

        return `${fullUrl}${!!queryString ? `?${queryString}` : ''}`;
    };

    const getHeaders = (options: RequestInit, bulkRequest = false) => {
        const headers = options.headers ? new Headers(options.headers) : new Headers();
        headers.set('Content-Type', `application/vnd.api+json${bulkRequest ? '; ext=bulk' : ''}`);
        headers.set('Accept', 'application/json');
        headers.set('X-Auth-Token', '65e4ca48-b359-463d-bd3e-0430559184c3');
        headers.set('X-Organization-Id', '12996');

        return headers;
    };

    const callApi = async <T>(
        url: string,
        queryParams?: ProductiveQueryParams,
        options?: RequestInit
    ) => {
        const finalUrl = getFullUrl(url, queryParams);
        const finalOptions = {
            ...options,
            headers: getHeaders(options || {}, !!queryParams?.pagination?.number)
        };

        const response: ProductiveResponse<T> = await fetch(finalUrl, finalOptions);
        const data: T & { errors: ProductiveErrorModel[] } = await response.json();

        if (response.ok) {
            response.data = data as T;
        }

        return response;
    };

    return {
        callApi
    };
};
