import { useDates } from 'hooks/dates';
import { useProductiveApi, ProductiveIncludedModel, ProductiveDataModel } from 'hooks/productive/api';

export interface BookingsResponseModel {
    data: ProductiveDataModel[];
    included?: ProductiveIncludedModel[];
}

export const useBookings = () => {
    const { callApi } = useProductiveApi();
    const { today } = useDates();

    const bookingsOnDate = async (date: Date = today()) =>
        await callApi<BookingsResponseModel>('/bookings', {
            filters: {
                before: date,
                after: date
            }
        });

    return {
        bookingsOnDate
    };
};
