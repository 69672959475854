import styled from '@emotion/styled';

export const User = styled.div`
    margin-bottom: 0.5rem;
`;

export const Name = styled.span`
    margin-left: 0.5rem;
    font-weight: bold;
`;
