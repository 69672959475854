import { formatRFC3339, parse, format, startOfDay, endOfDay, isSameDay, intervalToDuration, formatDuration, Locale, getISOWeek } from 'date-fns';
import { nl } from 'date-fns/locale';

export const useDates = () => ({
    formatRFC3339,
    parse,
    format,
    startOfDay,
    endOfDay,
    isSameDay,
    formatDuration,
    today: () => new Date(),
    defaultDate: () => new Date(),
    currentMillis: () => Date.now(),
    customFormat: (date: Date, outputFormat: string) => format(date, outputFormat, { locale: nl }),
    customFormatDuration: (durationInMs: number, options: {
        format?: string[],
        zero?: boolean,
        delimiter?: string,
        locale?: Locale
    } = {}) => {
        const duration = intervalToDuration({ start: 0, end: durationInMs });
        const finalOptions = {
            locale: nl,
            ...options
        };

        return formatDuration(duration, finalOptions);
    },
    msToTimeDuration: (durationInMs: number) => {
        const ms = durationInMs % 1000;
        durationInMs = (durationInMs - ms) / 1000;
        const secs = durationInMs % 60;
        durationInMs = (durationInMs - secs) / 60;
        const mins = durationInMs % 60;
        const hrs = (durationInMs - mins) / 60;

        return `${hrs}:${(mins < 10 ? `0${mins}` : mins)}`;
    },
    weekNumber: (date: Date) => getISOWeek(date),
    defaultDateFormat: 'yyyy-MM-dd',
    defaultDateTimeFormat: 'yyyy-MM-dd\'T\'HH:mm:ssXXX'
});
