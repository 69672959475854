import { FC } from 'react';
import { User as Wrapper, Name } from './styles';

interface UserProps {
    title?: string;
    img?: string;
}

const User: FC<UserProps> = ({ title, img }) => {
    return (
        <Wrapper>
            <img className="avatar" src={img} alt="Profielfoto" />
            <Name>{title}</Name>
        </Wrapper>
    );
};

export default User;
