import { useEffect, useState } from 'react';

import { Button } from 'components/Button';
import { DatePicker } from 'components/DatePicker';
import Select, { CustomSelectOptionModel } from 'components/Select';
import { useGoogleApi, MappedEvents } from 'hooks/googleApi';
import { useProductive, BudgetFilterOptions } from 'hooks/productive';
import { useDebounce } from 'hooks/debounce';
import { useDates } from 'hooks/dates';
import './App.css';
import User from 'components/User';
import { DefaultEmployeeMappings } from 'constants/EmployeeMappings';

const teamsCustomFieldId = '3438';

interface MappedEventItem {
    googleCalendarId: string;
    events: MappedEvents;
}

const App = () => {
    const { customFormat, today, currentMillis, isSameDay, customFormatDuration, msToTimeDuration, weekNumber } = useDates();
    const [lastRefresh, setLastRefresh] = useState<number>(currentMillis());
    const [budgetFilterOptions, setBudgetFilterOptions] = useState<BudgetFilterOptions>({
        bluenotionOnly: true,
        projectFilter: 'all',
        searchString: undefined,
        enabled: false
    });

    const debouncedBudgetFilterOptions = useDebounce(budgetFilterOptions, 250);

    const [date, setDate] = useState<Date>(today());
    const { dayOverviews, people, refreshData, teamsFilterOptions, setTeamsCustomFieldFilter, dealOverviews } = useProductive(date, debouncedBudgetFilterOptions);

    const { signInGoogle, signOutGoogle, allEventsInCalendarOnDate, isSignedIn } = useGoogleApi();
    const [mappedEvents, setMappedEvents] = useState<MappedEventItem[]>([]);
    const [selectedTeamFilterOption, setSelectedTeamFilterOption] = useState<CustomSelectOptionModel[]>([]);

    useEffect(() => {
        //Setup refresh timer
        const refreshTime = 60 * 1000; //Every 60 seconds

        const refreshInterval = setInterval(() => {
            const current = currentMillis();
            if (current - lastRefresh > refreshTime) {
                setLastRefresh(current);
            }
        }, refreshTime);

        return () => clearInterval(refreshInterval);
    }, []);

    useEffect(() => {
        refreshData();
    }, [lastRefresh]);

    const getAllEvents = async () => {
        const calendarIdsToFetch: string[] = people
            .map((p) => DefaultEmployeeMappings[p.id]?.googleCalendarId || '')
            .filter((gcId) => !!gcId);
        const mappedEvents = await Promise.all(calendarIdsToFetch.map(async (cId) => ({
            googleCalendarId: cId,
            events: await allEventsInCalendarOnDate(cId, date)
        })));

        setMappedEvents(mappedEvents);
    };

    useEffect(() => {
        if (isSignedIn) {
            getAllEvents();
        }
    }, [isSignedIn, date, people]);

    const isNumber = (number: number) => !isNaN(number) && isFinite(number);

    useEffect(() => {
        if (!selectedTeamFilterOption?.length) {
            //Automatically set to 'Bluenotion' team
            const teamOptions = teamsFilterOptions && teamsFilterOptions[teamsCustomFieldId];
            const bnOption = teamOptions?.find((o) => o.label === 'Bluenotion');
            const entreoOption = teamOptions?.find((o) => o.label === 'Entreo');
            const wilmarOption = teamOptions?.find((o) => o.label === 'Wilmar');
            let selectedTeamFilterOptions: CustomSelectOptionModel[] = [];

            if (bnOption) {
                selectedTeamFilterOptions = [...selectedTeamFilterOptions, bnOption];
            }

            if (entreoOption) {
                selectedTeamFilterOptions = [...selectedTeamFilterOptions, entreoOption];
            }

            if (wilmarOption) {
                selectedTeamFilterOptions = [...selectedTeamFilterOptions, wilmarOption];
            }

            setSelectedTeamFilterOption(selectedTeamFilterOptions);
        }
    }, [teamsFilterOptions]);

    useEffect(() => {
        setTeamsCustomFieldFilter(teamsCustomFieldId, selectedTeamFilterOption.map(o => o.value));
    }, [selectedTeamFilterOption]);

    const getEmployeeCalendarEvents = (employeeId: string) => {
        const employeeCalendarId = DefaultEmployeeMappings[employeeId]?.googleCalendarId;
        return mappedEvents.find((e) => e.googleCalendarId === employeeCalendarId)?.events.items ?? [];
    };

    const updateBudgetFilterOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value;

        setBudgetFilterOptions({
            ...budgetFilterOptions,
            [event.target.name]: value
        });
    };

    const setSelectedTeamFilters = (newlySelectedTeamFilterOption: CustomSelectOptionModel[] | undefined) => {
        setSelectedTeamFilterOption(newlySelectedTeamFilterOption ?? []);
    };

    return (
        <div>
            <div className="row">
                <div className="column">
                    <strong>Week {weekNumber(date)}</strong>
                    <DatePicker value={date} setValue={setDate} />
                    <a href="https://docs.google.com/spreadsheets/d/1T7utvO9eg0KWPsRfxe_IrEb-hsdr0mARIfx6LCeNXEM/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Bekijk kantoorrooster</a>
                </div>
                <div className="column">
                </div>
                <div className="column">
                    <h2>Google calendar instellingen</h2>
                    {!isSignedIn && <Button text="Aanmelden met Google" onClick={signInGoogle} />}
                    {isSignedIn && (
                        <div>
                            <div>
                                <Button text="Afmelden bij Google" onClick={signOutGoogle} />
                            </div>
                        </div>
                    )}
                </div>
                {
                    teamsFilterOptions && teamsFilterOptions[teamsCustomFieldId] && (
                        <div className="column">
                            <Select
                                name="team"
                                values={selectedTeamFilterOption}
                                options={[{ label: 'Geen', value: '' }, ...teamsFilterOptions[teamsCustomFieldId]]}
                                onChange={setSelectedTeamFilters}
                            />
                        </div>
                    )
                }
                <div className="column">
                </div>
            </div>
            <div className="row users__wrapper">
                {dayOverviews
                    ?.map((overview) => ({ overview, employeeAgendaItems: getEmployeeCalendarEvents(overview.employee.id) }))
                    /*?.filter(({ overview, employeeAgendaItems }) =>
                        overview.bookings.length > 0
                        || overview.tasks.length > 0
                        || overview.events.length > 0
                        || employeeAgendaItems.length > 0)
                    */.map(({ overview, employeeAgendaItems }, index) => {
                        const totalTaskCount = overview.tasks.length;
                        const completedTaskCount = overview.tasks.filter((t) => t.closed).length;

                        return (
                            <div key={index} className="users__user">
                                <User
                                    title={`${overview.employee.firstName} ${overview.employee.lastName}`}
                                    img={overview.employee.avatar}
                                />
                                {overview.tasks?.length > 0 && (
                                    <div>
                                        <strong>
                                            Ingeplande taken ({completedTaskCount}/{totalTaskCount})
                                        </strong>
                                        <ul>
                                            {overview.tasks.map((task, index) => (
                                                <li key={index}>
                                                    <a href={task.link} target="_blank" rel="noreferrer">
                                                        {`[${task.project}] `}
                                                        {task.closed ? <del>{task.title}</del> : <span>{task.title}</span>}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {overview.bookings.length > 0 && (
                                    <div>
                                        <strong>Vaste boekingen</strong>
                                        <ul>
                                            {overview.bookings.map((booking, index) => (
                                                <li key={index}>
                                                    [{booking.title}] {booking.hours} uur
                                                    {booking.note && (
                                                        <ul>
                                                            <li>Opmerkingen: {booking.note}</li>
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {overview.events.length > 0 && (
                                    <div>
                                        <strong>Overige gebeurtenissen</strong>
                                        <ul>
                                            {overview.events.map((event, index) => (
                                                <li key={index}>
                                                    {`[${event.title}] ${event.time > 0
                                                        ? `voor ${customFormatDuration(event.time * 60 * 1000)}`
                                                        : ''
                                                        } `}
                                                    {!isSameDay(event.startDate, event.endDate) && (
                                                        <span>
                                                            van {customFormat(event.startDate, 'd-M')} t/m{' '}
                                                            {customFormat(event.endDate, 'd-M')}
                                                        </span>
                                                    )}
                                                    {event.note && (
                                                        <ul>
                                                            <li>Opmerkingen: {event.note}</li>
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {isSignedIn && employeeAgendaItems.length > 0 && (
                                    <div>
                                        <strong>Agenda</strong>
                                        <ul>
                                            {employeeAgendaItems.map((e, index) => (
                                                <li key={index}>
                                                    {customFormat(e.startTime, 'HH:mm')} -{' '}
                                                    {customFormat(e.endTime, 'HH:mm')}: {e.title}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
            <div>
                {
                    isSignedIn &&
                    <div className="row">
                        <label>
                            Toon budgetten en uurtarieven?
                            <input name="enabled" type="checkbox" checked={budgetFilterOptions.enabled} onChange={updateBudgetFilterOptions} />
                        </label>
                    </div>
                }
                {
                    budgetFilterOptions.enabled && (
                        <div>
                            <div className="row">
                                <label>
                                    Toon alleen Bluenotion budgetten?
                                    <input name="bluenotionOnly" type="checkbox" checked={budgetFilterOptions.bluenotionOnly} onChange={updateBudgetFilterOptions} />
                                </label>
                            </div>
                            <div className="row">
                                <label>
                                    Toon alle projecten
                                    <input name="projectFilter" type="radio" value="all" checked={budgetFilterOptions.projectFilter === 'all'} onChange={updateBudgetFilterOptions} />
                                </label>
                                <br />
                                <label>
                                    Toon alleen projecten met gewerkte uren en budget?
                                    <input name="projectFilter" type="radio" value="worked" checked={budgetFilterOptions.projectFilter === 'worked'} onChange={updateBudgetFilterOptions} />
                                </label>
                                <br />
                                <label>
                                    Toon alleen projecten over budget?
                                    <input name="projectFilter" type="radio" value="over" checked={budgetFilterOptions.projectFilter === 'over'} onChange={updateBudgetFilterOptions} />
                                </label>
                                <br />
                                <label>
                                    Toon alleen projecten met ontbrekende gegevens?
                                    <input name="projectFilter" type="radio" value="missing" checked={budgetFilterOptions.projectFilter === 'missing'} onChange={updateBudgetFilterOptions} />
                                </label>
                            </div>
                            <div className="row">
                                <label>Zoeken</label>
                                <input name="searchString" type="text" value={budgetFilterOptions.searchString} onChange={updateBudgetFilterOptions} />
                            </div>
                            <div className="row users__wrapper">
                                {
                                    dealOverviews && dealOverviews
                                        .sort((d1, d2) => {
                                            if (!isNumber(d1.projectedHourlyRate)) {
                                                return -1;
                                            }

                                            if (!isNumber(d2.projectedHourlyRate)) {
                                                return 1;
                                            }

                                            return d1.actualHourlyRate - d2.actualHourlyRate;
                                        })
                                        .map((d, index) => (
                                            <div key={index} className="users__user">
                                                <a href={d.projectLink} target="_blank" rel="noreferrer">Project: {d.projectName} ({`${d.id}`})</a> <br />
                                                <a href={d.budgetLink} target="_blank" rel="noreferrer">Budget naam: {d.budgetName}</a> <br />
                                                Budget totaal: {d.budgetTotal} <br />
                                                Gebudgeteerde uren: {msToTimeDuration(d.budgetedTimeHours * 60 * 60 * 1000)} <br />
                                                Gewerkte uren: {msToTimeDuration(d.billableTimeHours * 60 * 60 * 1000)} <br />
                                                Geschat uurtarief: {isNaN(d.projectedHourlyRate) || !isFinite(d.projectedHourlyRate) ? '-- kon niet berekend worden, is er een budget totaal & uren inschatting ingevuld?' : d.projectedHourlyRate} <br />
                                                <span style={{
                                                    color: d.differenceHourlyRate < 0 ? 'red' : undefined
                                                }}
                                                >
                                                    Gerealizeerde uurtarief: {isNaN(d.actualHourlyRate) ? '-- kon niet berekend worden, is er een budget totaal & uren inschatting ingevuld?' : d.actualHourlyRate}</span> <br />
                                                <br />
                                                Gerealizeerde omzet: {d.revenue}
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default App;
