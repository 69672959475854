import { useDates } from 'hooks/dates';
import { useProductiveApi, ProductiveIncludedModel, ProductiveDataModel } from 'hooks/productive/api';

export interface TasksResponseModel {
    data: ProductiveDataModel[];
    included?: ProductiveIncludedModel[];
}

export const useTasks = () => {
    const { today } = useDates();
    const { callApi } = useProductiveApi();

    const tasksDueOnDate = async (date: Date = today()) =>
        await callApi<TasksResponseModel>('/tasks', {
            filters: {
                due_date_on: date
            },
            pagination: {
                number: 1,
                size: 100
            }
        });

    return {
        tasksDueOnDate
    };
};
