
import { useEffect, useState } from 'react';
import { useDates } from 'hooks/dates';
import { findInIncludedByTypeAndId, ProductiveAttributesObjectModel, ProductiveDataModel } from 'hooks/productive/api';
import { usePeople } from 'hooks/productive/people';
import { useTasks, TasksResponseModel } from 'hooks/productive/tasks';
import { useBookings, BookingsResponseModel } from 'hooks/productive/bookings';
import { useCustomFields, CustomFieldsResponseModel } from 'hooks/productive/customFields';
import { useDeals, DealsResponseModel } from 'hooks/productive/deals';
//import { ProjectsResponseModel, useProjects } from './projects';

interface MappedEvent {
    title: string;
    startDate: Date;
    endDate: Date;
    note?: string;
    time: number;
}
interface MappedTask {
    title: string;
    project: string;
    closed: boolean;
    link?: string;
}

interface MappedBooking {
    time: number;
    title: string;
    hours: number;
    note?: string;
}
interface DayOverview {
    employee: DayOverviewEmployee;
    tasks: MappedTask[];
    bookings: MappedBooking[];
    events: MappedEvent[];
}

interface DealOverview {
    id: string;
    budgetName: string;
    projectName: string;
    budgetedTimeHours: number;
    billableTimeHours: number;
    revenue: number;
    budgetTotal: number;
    projectedHourlyRate: number;
    differenceHourlyRate: number;
    actualHourlyRate: number;
    projectLink: string;
    budgetLink: string;
}

interface DayOverviewEmployee {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
}

export interface CustomFieldsFilterOptions {
    [keyof: string]: {
        label: string;
        value: string;
    }[];
}

export interface FilterModel {
    customFields?: {
        [keyof: string]: string[];
    }
}

export interface BudgetFilterOptions {
    bluenotionOnly: boolean;
    projectFilter: 'all' | 'worked' | 'missing' | 'over';
    searchString?: string;
    enabled: boolean;
}

export interface ProjectFilterOptions {
    bluenotionOnly?: boolean;
}

export const useProductive = (date: Date, budgetFilterOptions: BudgetFilterOptions) => {
    //const [projectsData, setProjectsData] = useState<ProjectsResponseModel>();
    const [bookingsData, setBookingsData] = useState<BookingsResponseModel>();
    const [people, setPeople] = useState<ProductiveDataModel[]>([]);
    const [tasksData, setTasksData] = useState<TasksResponseModel>();
    const [dayOverviews, setDayOverviews] = useState<DayOverview[]>();
    const [dealsData, setDealsData] = useState<DealsResponseModel>();
    const [dealOverviews, setDealOverviews] = useState<DealOverview[]>();

    const [teamsCustomFieldData, setTeamsCustomFieldsData] = useState<CustomFieldsResponseModel>();
    const [teamsFilterOptions, setTeamsFilterOptions] = useState<CustomFieldsFilterOptions>();
    const [filters, setFilters] = useState<FilterModel>();

    const { parse, defaultDate, defaultDateFormat } = useDates();
    const { bookingsOnDate } = useBookings();
    const { allPeople } = usePeople();
    const { tasksDueOnDate } = useTasks();
    const { customFieldByName } = useCustomFields();
    const { allDeals } = useDeals();
    //const { allProjects } = useProjects();

    const getBookingsOnDate = async (date: Date) => setBookingsData((await bookingsOnDate(date))?.data);
    const getTasksDueOnDate = async (date: Date) => setTasksData((await tasksDueOnDate(date))?.data);
    //const getAllProjects = async () => setProjectsData((await allProjects())?.data);
    const getAllPeople = async () => {
        let filteredPeople = (await allPeople()).data?.data || [];

        if (filters?.customFields) {
            const customFieldFilters = Object.entries(filters.customFields)
                .map(([customFieldId, customFieldValues]) => (p: ProductiveDataModel) => {
                    const customFieldAttribute = p.attributes['custom_fields'] as ProductiveAttributesObjectModel;
                    return customFieldAttribute && customFieldValues.some(value => customFieldAttribute[customFieldId] === value);
                });

            customFieldFilters.forEach((filter) => filteredPeople = filteredPeople.filter((p) => filter(p)));
        }

        setPeople(filteredPeople);
    };
    const getTeamsCustomField = async () => setTeamsCustomFieldsData((await customFieldByName('teams'))?.data);
    const getAllDeals = async () => {
        const filteredDeals = (await allDeals())?.data;
        setDealsData(filteredDeals);
    };

    const setTeamsCustomFieldFilter = (teamsCustomFieldId: string, selectedValues?: string[]) => {
        if (!!selectedValues) {
            setFilters(({ ...filters, customFields: { [teamsCustomFieldId]: selectedValues } }));
        } else if (filters?.customFields) {
            const { [teamsCustomFieldId]: deleted, ...newCustomFields } = filters.customFields;
            setFilters(({ ...filters, customFields: newCustomFields }));
        }
    };

    const refreshData = async (withFinance = false) => {
        let requests = [
            getAllPeople(),
            getBookingsOnDate(date),
            getTasksDueOnDate(date),
            getTeamsCustomField(),
            //getAllProjects()
        ];

        if (withFinance) {
            // Because getAllDeals is a bulk API call and is not subject to minutely change, we do not want to reload it unless explicitly requested.
            requests = [...requests, getAllDeals()];
        }

        await Promise.all(requests);
    };

    useEffect(() => {
        const newDayOverviews: DayOverview[] = people
            .map(p => {
                const employee: DayOverviewEmployee = {
                    id: p.id,
                    firstName: p.attributes['first_name'] as string,
                    lastName: p.attributes['last_name'] as string,
                    avatar: p.attributes['avatar_url'] as string || `https://eu.ui-avatars.com/api/?background=random&name=${p.attributes['first_name']} ${p.attributes['last_name']}`
                };

                const tasks = tasksData?.data
                    .filter(t => t.relationships['assignee'].data?.id === p.id)
                    .map(t => {
                        const project = findInIncludedByTypeAndId(tasksData.included, 'projects', t.relationships['project']?.data.id);
                        const task: MappedTask = {
                            title: t.attributes['title'] as string,
                            closed: t.attributes['closed_at'] != null,
                            project: project?.attributes['name'] as string || '',
                            link: `https://app.productive.io/${t.relationships['organization']?.data.id}/task/${t.id}}`
                        };

                        return task;
                    }) || [];

                const filteredBookings = bookingsData?.data
                    .filter(b => b.relationships['person'].data?.id === p.id);

                const bookings = filteredBookings
                    ?.filter(b => !!b.relationships['service']?.data?.id)
                    .map(b => {
                        const serviceId = b.relationships['service']?.data?.id;
                        const dealId = findInIncludedByTypeAndId(bookingsData?.included, 'services', serviceId)?.relationships['deal']?.data.id;
                        const deal = dealId ? findInIncludedByTypeAndId(bookingsData?.included, 'deals', dealId) : null;

                        const booking: MappedBooking = {
                            title: deal?.attributes['name'] as string || '',
                            hours: parseInt(b.attributes['hours'] as string) || 0,
                            note: b.attributes['note'] as string,
                            time: parseInt(b.attributes['time'] as string) || 0
                        };

                        return booking;
                    }) || [];

                const events = filteredBookings
                    ?.filter(b => !!b.relationships['event']?.data?.id)
                    .map(b => {
                        const eventId = b.relationships['event'].data.id;
                        const event = eventId ? findInIncludedByTypeAndId(bookingsData?.included, 'events', eventId) : null;

                        const mappedEvent: MappedEvent = {
                            startDate: parse(b.attributes['started_on'] as string, defaultDateFormat, defaultDate()),
                            endDate: parse(b.attributes['ended_on'] as string, defaultDateFormat, defaultDate()),
                            title: event?.attributes['name'] as string || '',
                            note: b.attributes['note'] as string,
                            time: parseInt(b.attributes['time'] as string) || 0
                        };

                        return mappedEvent;
                    }) || [];

                return {
                    employee,
                    tasks,
                    bookings,
                    events
                };
            });

        setDayOverviews(newDayOverviews);
    }, [tasksData, people, bookingsData]);

    useEffect(() => {
        const newDealOverviews: DealOverview[] = (dealsData?.data ?? [])
            .map(d => {
                const projectId = d.relationships['project']?.data?.id;
                const project = projectId ? findInIncludedByTypeAndId(dealsData?.included, 'projects', projectId) : null;

                const customFieldAttribute = d.attributes['custom_fields'] as ProductiveAttributesObjectModel;
                const isBluenotionBudget = customFieldAttribute && customFieldAttribute['6207'] === '21328';

                const budgetedTimeHours = parseInt((d.attributes['budgeted_time'] as string ?? '0')) / 60;
                const billableTimeHours = parseInt((d.attributes['billable_time'] as string ?? '0')) / 60;
                const revenue = parseInt((d.attributes['revenue'] as string ?? '0')) / 100;
                const budgetTotal = parseInt((d.attributes['budget_total'] as string ?? '0')) / 100;
                const projectedHourlyRate = budgetTotal / budgetedTimeHours;
                const actualHourlyRate = budgetedTimeHours >= billableTimeHours
                    ? budgetTotal / budgetedTimeHours
                    : revenue / billableTimeHours;
                const differenceHourlyRate = Math.min(-(projectedHourlyRate - actualHourlyRate), 0);

                return {
                    id: d.id,
                    budgetName: d.attributes['name'] as string ?? '',
                    projectName: project?.attributes['name'] as string ?? '',
                    budgetedTimeHours,
                    billableTimeHours,
                    budgetTotal,
                    revenue,
                    projectedHourlyRate,
                    actualHourlyRate,
                    differenceHourlyRate,
                    isBluenotionBudget,
                    projectLink: `https://app.productive.io/${d.relationships['organization']?.data.id}/projects/${projectId}}/budgets`,
                    budgetLink: `https://app.productive.io/${d.relationships['organization']?.data.id}/d/deal/${d.id}}`
                };
            })
            .filter(d => {
                switch (budgetFilterOptions.projectFilter) {
                    case 'worked':
                        return d.budgetedTimeHours > 0 && d.billableTimeHours > 0 && d.revenue > 0;
                    case 'missing':
                        return d.budgetedTimeHours === 0 || d.billableTimeHours === 0 || d.revenue === 0;
                    case 'over':
                        return (d.budgetedTimeHours > 0 && d.billableTimeHours > 0 && d.revenue > 0) && d.billableTimeHours > d.budgetedTimeHours;
                    case 'all':
                    default:
                        return true;
                }
            })
            .filter(d => !budgetFilterOptions.bluenotionOnly || d.isBluenotionBudget)
            .filter(d => !budgetFilterOptions.searchString || budgetFilterOptions.searchString.length < 3 || [d.projectName, d.budgetName].some(s => s.toLowerCase().includes((budgetFilterOptions.searchString as string).toLowerCase())))
            .sort((a, b) => a.projectName > b.projectName ? 1 : -1);

        setDealOverviews(newDealOverviews);
    }, [dealsData, budgetFilterOptions]);

    useEffect(() => {
        getAllPeople();
        getTeamsCustomField();
    }, []);

    useEffect(() => {
        if (budgetFilterOptions?.enabled) {
            getAllDeals();
        }
    }, [budgetFilterOptions?.enabled]);

    useEffect(() => {
        getAllPeople();
    }, [filters]);

    useEffect(() => {
        getBookingsOnDate(date);
        getTasksDueOnDate(date);
    }, [date]);

    useEffect(() => {
        const options = teamsCustomFieldData?.data
            .reduce((totalCustomFieldFilterModel, customField) => {
                const optionIds = customField.relationships['options']?.data.map((d) => d.id);
                const mappedOptions = optionIds
                    .map((id) => findInIncludedByTypeAndId(teamsCustomFieldData?.included, 'custom_field_options', id))
                    .map((option) => ({ label: option?.attributes['name'], value: option?.id }));

                return {
                    ...totalCustomFieldFilterModel,
                    [customField.id]: mappedOptions
                };
            }, {});

        if (options) {
            setTeamsFilterOptions(options);
        }
    }, [teamsCustomFieldData]);

    return {
        dayOverviews,
        people,
        teamsFilterOptions,
        dealOverviews,
        setTeamsCustomFieldFilter,
        refreshData,
    };
};
