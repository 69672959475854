import { FC } from 'react';
import Select from 'react-select';
import { Select as Wrapper } from './styles';

interface CustomSelectProps {
    name: string;
    values?: CustomSelectOptionModel[];
    options?: CustomSelectOptionModel[];
    onChange?: (values?: CustomSelectOptionModel[]) => void;
}

export interface CustomSelectOptionModel {
    label: string;
    value: string;
}

const CustomSelect: FC<CustomSelectProps> = ({ name, values, options, onChange }) => {
    return (
        <Wrapper>
            <Select
                isMulti
                name={name}
                options={options}
                value={values}
                hideSelectedOptions
                onChange={(values) => onChange && onChange(values as CustomSelectOptionModel[])}
            />
        </Wrapper>
    );
};

export default CustomSelect;
