import { useProductiveApi, ProductiveDataModel } from 'hooks/productive/api';

export interface PeopleResponseModel {
    data: ProductiveDataModel[];
}

export const usePeople = () => {
    const { callApi } = useProductiveApi();

    const allPeople = async () =>
        await callApi<PeopleResponseModel>('/people', {
            pagination: {
                size: 100
            },
            filters: {
                status: 1
            }
        });

    const getPerson = async (personId: number) =>
        await callApi<ProductiveDataModel>('/people', {
            filters: {
                personId
            }
        });

    return {
        allPeople,
        getPerson
    };
};
