import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps {
    value: Date,
    setValue: (newValue: Date) => void
}

export const DatePicker = ({ value, setValue }: DatePickerProps) => {
    return (
        <div>
            <ReactDatePicker
                selected={value}
                dateFormat="dd-MM-yyyy"
                onChange={(newValue: Date) => setValue(newValue)}
            />
        </div>
    );
};

export default DatePicker;
