import { useProductiveApi, ProductiveDataModel, ProductiveIncludedModel, ProductiveMetaModel } from 'hooks/productive/api';

export interface DealsResponseModel {
    data: ProductiveDataModel[];
    meta?: ProductiveMetaModel;
    included?: ProductiveIncludedModel[];
}
export interface BudgetFilterOptions {
    bluenotionOnly: boolean;
    projectFilter: 'all' | 'worked' | 'missing' | 'over';
    searchString?: string;
}

export const useDeals = () => {
    const { callApi } = useProductiveApi();

    const allDeals = async (pageNumber = 1) => {
        const response = await callApi<DealsResponseModel>('/deals', {
            pagination: {
                size: 200,
                number: pageNumber
            }
        });

        if (response.data?.meta?.current_page !== response.data?.meta?.total_pages) {
            const nextDeals = await allDeals(pageNumber + 1);

            if (response.data && nextDeals.data && response.data.included && nextDeals.data.included) {
                response.data.data = [...response.data.data, ...nextDeals.data.data];
                response.data.included = [...response.data.included, ...nextDeals.data.included];
            }
        }

        return response;
    };

    return {
        allDeals
    };
};