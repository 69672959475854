interface EmployeeMappings {
    [key: string]: {
        birthday?: Date;
        googleCalendarId?: string;
    }
}

export const DefaultEmployeeMappings: EmployeeMappings = {
    140314: { // Anne Derks
        birthday: new Date(1992, 1, 30),
        googleCalendarId: 'annederksbluenotion@gmail.com',
    },
    143411: { // Anouk Biesters
        birthday: new Date(),
    },
    140310: { // Bard Duijs
        birthday: new Date(),
        googleCalendarId: 'bardduijs@gmail.com',
    },
    140918: { // Bart Koelewijn
        birthday: new Date(1992, 10, 8),
        googleCalendarId: 'bartkoelewijnnl@gmail.com',
    },
    142745: { // Dorien Duijsings
        birthday: new Date(),
    },
    143844: { // Ellen Janssen
        birthday: new Date(1995, 6, 2),
        googleCalendarId: '',
    },
    143418: { // Frank Niewerth
        birthday: new Date(1984, 8, 27),
        googleCalendarId: '',
    },
    143634: { // Jelle Althuizen
        birthday: new Date(),
    },
    140499: { // Jelle Streefkerk
        birthday: new Date(1994, 6, 2),
        googleCalendarId: '35buhc4vmvpnhtvlhirpog2dng@group.calendar.google.com',
    },
    143610: { // Joel de Bont
        birthday: new Date(1994, 5, 15),
        googleCalendarId: '',
    },
    143410: { // Max Kerkhoffs
        birthday: new Date(),
    },
    140317: { // Niels Bokmans
        birthday: new Date(1996, 10, 1),
        googleCalendarId: 'h1plupuhr9o0gfqkk2sm9h2lfo@group.calendar.google.com',
    },
    143414: { // Paul Martina
        birthday: new Date()
    },
    143569: { // Paul Treffers
        birthday: new Date(1988, 9, 24),
        googleCalendarId: '',
    },
    144974: { // Rianne Durenkamp 1
        birthday: new Date()
    },
    144983: { // Rianne Durenkamp 2
        birthday: new Date()
    },
    143412: { // Robbin Biesheuvel
        birthday: new Date(1985, 9, 10),
        googleCalendarId: 'robbinwilmar@gmail.com',
    },
    143611: { // Robert Visser
        birthday: new Date(1981, 9, 3),
        googleCalendarId: 'kgueo54havr9hqhq92t5tmme78@group.calendar.google.com',
    },
    145076: { // Tom Strik
        birthday: new Date()
    },
    143860: { // Tom van Grinsven
        birthday: new Date(1992, 12, 24),
        googleCalendarId: '',
    },
    140316: { // Yannic Smeets
        birthday: new Date(1992, 1, 11),
        googleCalendarId: 'yannic.smeets@gmail.com',
    },
    166896: { // Roel Dekkers
        birthday: new Date(1994, 3, 29),
        googleCalendarId: ''
    },
    192684: { // Sjoerd Crooijmans
        birthday: new Date(1997, 11, 17),
        googleCalendarId: 'sjoerd.crooijmans.bluenotion@gmail.com'
    },
    242664: { // Velislav Stefanov
        birthday: new Date(1996, 12, 27),
        googleCalendarId: ''
    },
    251437: { // Mark Wouters
        birthday: new Date(2001, 8, 19),
        googleCalendarId: ''
    },
    261097: { // Leo Cornelissen
        birthday: new Date(1997, 1, 24),
        googleCalendarId: ''
    },
    270219: { // Jesse Bekke
        googleCalendarId: ''
    }
};
