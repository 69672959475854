import { useState, useEffect } from 'react';
import { useDates } from 'hooks/dates';

const apiKey = 'AIzaSyBgKRV-IgKMGfWoGmcBR3bRcAeyDas5UP8';
const discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const clientId = '191899263015-kppl7f0haru093t12mukd0f0v9eveg0c.apps.googleusercontent.com';
const scopes = ['https://www.googleapis.com/auth/calendar.readonly', 'https://www.googleapis.com/auth/calendar.events.readonly'];

const gapiSrc = 'https://apis.google.com/js/api.js';

let addedScripts: HTMLScriptElement[] = [];

export interface ListCalendarsResult {
    items: CalendarResult[];
}

export interface CalendarResult {
    id: string;
    name: string;
}

export interface MappedCalendar {
    id: string;
    name: string;
}

export interface MappedEvents {
    id: string;
    items: MappedEvent[]
}
interface MappedEvent {
    id: string;
    title: string;
    startTime: Date;
    endTime: Date;
    organizer: string;
    organizerEmail: string;
}

export const useGoogleApi = () => {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [calendars, setCalendars] = useState<MappedCalendar[]>([]);

    const { formatRFC3339, endOfDay, defaultDate, parse, startOfDay, defaultDateTimeFormat } = useDates();

    const getAllCalendars = async () => setCalendars(isSignedIn ? await allCalendars() : []);

    useEffect(() => {
        getAllCalendars();
    }, [isSignedIn]);

    const onLoad = () => {
        gapi.load('client:auth2', () => {
            gapi.client.init({
                apiKey: apiKey,
                discoveryDocs: discoveryDocs,
                clientId: clientId,
                scope: scopes.join(' ')
            }).then(() => {
                gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
                setIsSignedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
            });
        });
    };

    const signInGoogle = () => {
        gapi.auth2.getAuthInstance().signIn();
    };

    const signOutGoogle = () => {
        gapi.auth2.getAuthInstance().signOut();
    };

    const allCalendars = async () => {
        const response = await gapi.client.calendar.calendarList.list();
        const calendars: gapi.client.calendar.CalendarList = JSON.parse(response.body);
        const mappedCalendars: MappedCalendar[] = calendars.items?.map(c => ({
            id: c.id || '',
            name: c.summary || ''
        })) || [];

        return mappedCalendars;
    };

    const allEventsInCalendarOnDate = async (calendarId: string, date: Date): Promise<MappedEvents> => {
        const response = await gapi.client.calendar.events.list({
            calendarId,
            timeMin: formatRFC3339(startOfDay(date)),
            timeMax: formatRFC3339(endOfDay(date)),
            showDeleted: false,
            singleEvents: true,
            showHiddenInvitations: false
        });

        const defaultStart = startOfDay(date);
        const defaultEnd = endOfDay(date);

        const events: gapi.client.calendar.Events = JSON.parse(response.body);
        return {
            id: events.etag || '',
            items: (events.items || []).map(e => ({
                title: e?.summary || '',
                id: e?.id || '',
                startTime: e?.start?.dateTime ? parse(e.start.dateTime, defaultDateTimeFormat, defaultDate()) : defaultStart,
                endTime: e?.end?.dateTime ? parse(e.end.dateTime, defaultDateTimeFormat, defaultDate()) : defaultEnd,
                organizer: e?.organizer?.displayName || '',
                organizerEmail: e?.organizer?.email || ''
            })).sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
        };
    };

    useEffect(() => {
        let script = addedScripts.find(el => el.src === gapiSrc);

        if (!script) {
            script = document.createElement('script');
            script.src = gapiSrc;
            script.async = true;
            script.defer = true;
            script.addEventListener('load', onLoad);
            (document.head as any).appendChild(script);
            addedScripts.push(script);
        } else {
            onLoad();
        }

        return () => {
            script?.removeEventListener('load', onLoad);
            script?.remove();
            addedScripts = addedScripts.filter(s => s.src === gapiSrc);
        };
    }, []);

    return {
        allEventsInCalendarOnDate,
        signInGoogle,
        signOutGoogle,
        isSignedIn,
        calendars,
    };
};
