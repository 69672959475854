import { useProductiveApi, ProductiveAttributesModel, ProductiveRelationshipsArrayModel, ProductiveIncludedModel } from 'hooks/productive/api';

export interface CustomFieldsResponseModel {
    data: CustomFieldModel[];
    included?: ProductiveIncludedModel[];
}

export interface CustomFieldModel {
    id: string;
    attributes: ProductiveAttributesModel;
    relationships: ProductiveRelationshipsArrayModel;
}

export interface CustomFieldOptionsModel {
    data: CustomFieldOptionModel[];
}

export interface CustomFieldOptionModel {
    id: string;
}

export const useCustomFields = () => {
    const { callApi } = useProductiveApi();

    const customFieldByName = async (name: string) =>
        await callApi<CustomFieldsResponseModel>('/custom_fields', {
            filters: {
                name
            }
        });

    return {
        customFieldByName
    };
};
